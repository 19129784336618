import { t } from '@lingui/macro';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from '../../../components/ui/button/Button';
import type { ImageType } from '../../../types.d/storeTypes';
import styles from './CreatorOfMonth.module.scss';

export interface CreatorOfMonthProps {
  logo: ImageType | undefined;
  profileImage: ImageType | undefined;
  featuredMobileImage: ImageType | undefined;
  featuredDesktopImage: ImageType | undefined;
  creatorName: string;
  subtitleText?: string;
  descriptionText?: string;
  disclaimerContent: string;
  disclaimerCtaUrl: string;
  disclaimerCtaText: string;
  ctaTitle: string;
  ctaUrl: string;
  newTab: boolean;
}

/**
 * Category Header Component
 *
 * @param logo - string - url for logo image on top
 * @param profileImage - string - url for user image
 * @param featuredMobileImage - string - url featured image for mobile
 * @param featuredDesktopImage - string - url featured image for desktop
 * @param creatorName - string - Creator Name
 * @param subtitleText - string - subtitle text
 * @param descriptionText - string - description text
 * @param disclaimerContent - string - disclaimer content
 * @param disclaimerCtaUrl - string - disclaimer CTA URL
 * @param disclaimerCtaText - string - disclaimer CTA Text
 * @param ctaTitle - string - CTA Title
 * @param ctaUrl - string - CTA URL
 * @param newTab - boolean - should cta open a new tab
 */
export const CreatorOfMonth = ({
  logo,
  profileImage,
  featuredMobileImage,
  featuredDesktopImage,
  creatorName,
  subtitleText,
  descriptionText,
  disclaimerContent,
  disclaimerCtaUrl,
  disclaimerCtaText,
  ctaTitle,
  ctaUrl,
  newTab,
}: CreatorOfMonthProps) => {
  return (
    <div
      className={[styles.creatorOfMonth__wrapper, 'grid container'].join(' ')}
    >
      <div
        className={[styles.creatorOfMonth__sidebarWrapper, 'col xs4 l1-3'].join(
          ' '
        )}
      >
        <div className={styles.creatorOfMonth__header}>
          {logo && (
            <div className={styles.creatorOfMonth__logoWrapper}>
              <Image
                src={logo.url}
                alt={logo.title}
                title={logo.title}
                fill
                sizes="(min-width: 576px) 100px, 80px"
              />
            </div>
          )}
          <h2
            className={clsx(
              styles.creatorOfMonth__creatorName,
              'text-heading-h1-mobile',
              'text-heading-h1-desktop'
            )}
          >
            {creatorName}
          </h2>
        </div>

        <div className="month-creator__description--wrapper">
          {subtitleText && (
            <p
              className={clsx(
                styles.creatorOfMonth__descriptionTag,
                'text-body-2-mobile',
                'text-body-1-desktop'
              )}
            >
              {subtitleText}
            </p>
          )}

          <div className={styles.creatorOfMonth__descriptionBody}>
            {profileImage && (
              <div className={styles['creatorOfMonth__profileImage--wrapper']}>
                <Image
                  className={styles.creatorOfMonth__profileImage}
                  src={profileImage.url}
                  fill
                  sizes="(min-width: 576px) 105px, 75px"
                  alt={profileImage.title}
                  title={profileImage.title}
                />
              </div>
            )}
            {descriptionText && (
              <blockquote
                className={clsx(
                  styles.creatorOfMonth__descriptionText,
                  'text-body-2-mobile',
                  'text-body-1-desktop'
                )}
              >
                {descriptionText}
              </blockquote>
            )}
          </div>

          {ctaTitle && ctaUrl && (
            <Button
              className={styles.creatorOfMonth__cta}
              href={ctaUrl}
              type={'button'}
              modifiers={['black']}
              target={newTab ? '_blank' : undefined}
            >
              <p
                className={[
                  'text-utility-utility-desktop',
                  'text-utility-utility-small-mobile',
                ].join(' ')}
              >
                {ctaTitle}
              </p>
            </Button>
          )}

          <div
            className={[
              styles.creatorOfMonth__subscription,
              styles.creatorOfMonth__desktopComponent,
            ].join(' ')}
          >
            {disclaimerContent && (
              <span
                className={clsx(
                  styles.creatorOfMonth__subscriptionText,
                  'text-body-small'
                )}
              >
                {disclaimerContent}
              </span>
            )}
            {disclaimerCtaText && disclaimerCtaUrl && (
              <Link
                className={styles.creatorOfMonth__subscriptionLink}
                href={disclaimerCtaUrl}
                target="_blank"
                aria-label={t({
                  id: 'creatorOfMonth.subscription.link-label',
                  message: `${disclaimerCtaText}, opens a new tab`,
                })}
              >
                <span
                  className={clsx(
                    styles.creatorOfMonth__subscriptionLink,
                    'text-body-small'
                  )}
                >
                  {disclaimerCtaText}
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div
        className={[
          styles.creatorOfMonth__mainImageContainer,
          'col xs4 l4-12',
        ].join(' ')}
      >
        {featuredMobileImage && (
          <Image
            className={[
              styles.creatorOfMonth__mainImage,
              styles.creatorOfMonth__mobileComponent,
            ].join(' ')}
            src={featuredMobileImage.url}
            alt={featuredMobileImage.title}
            title={featuredMobileImage.title}
            fill
            sizes="(min-width: 576px) 700px, 100vw"
          ></Image>
        )}
        {featuredDesktopImage && (
          <Image
            className={[
              styles.creatorOfMonth__mainImage,
              styles.creatorOfMonth__desktopComponent,
            ].join(' ')}
            src={featuredDesktopImage.url}
            alt={featuredDesktopImage.title}
            title={featuredDesktopImage.title}
            fill
            sizes="1040px"
          ></Image>
        )}
      </div>

      <div
        className={[
          styles.creatorOfMonth__subscription,
          styles.creatorOfMonth__mobileComponent,
        ].join(' ')}
      >
        {disclaimerContent && (
          <p
            className={clsx(
              styles.creatorOfMonth__subscriptionText,
              'text-body-small'
            )}
          >
            {disclaimerContent}
          </p>
        )}
        {disclaimerCtaText && disclaimerCtaUrl && (
          <Link
            href={disclaimerCtaUrl}
            target="_blank"
            aria-label={t({
              id: 'creatorOfMonth.subscription.link-label',
              message: `${disclaimerCtaText}, opens a new tab`,
            })}
          >
            <span
              className={clsx(
                styles.creatorOfMonth__subscriptionLink,
                'text-body-small'
              )}
            >
              {disclaimerCtaText}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};
